<template>
  <el-card shadow="never">
    <el-row>
      <el-col :span="20">
        <div class="inputs-sections">
          <el-form
            size="mini"
            :inline="true"
            @submit.native.prevent
          >
            <keyword-value
              v-model="keyword"
              @keyup.enter.native="submit"
            ></keyword-value>
          </el-form>
          <el-form
            size="mini"
            @submit.native.prevent
          >
            <filters
              v-model="keywordFilters"
              class="filters-form-item"
            ></filters>
            <el-form-item :label="$t('country')">
              <select-search-engines
                v-if="defaultCountryIsoCode"
                v-model="isoCountryCode"
                :countries="countries"
              >
              </select-search-engines>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="action">
          <el-button
            type="primary"
            @click="submit"
            >{{ $t("search") }}</el-button
          >
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import SelectSearchEngines from "@/components/formItems/SelectSearchEngines";
import Filters from "@/pages/KeywordsSearch/Shared/FormItems/Filters";
import KeywordValue from "@/pages/KeywordsSearch/Shared/FormItems/KeywordValue";

export default {
  components: {
    Filters,
    KeywordValue,
    SelectSearchEngines,
  },
  props: {
    defaultCountryIsoCode: {
      type: String,
      default: null
    },
    countries:{
      type: Array,
      default: () => null
    },
  },
  data() {
    return {
      keyword: "",
      keywordFilters: [],
      isoCountryCode: this.defaultCountryIsoCode,
    };
  },
  methods: {
    submit() {
      const request = {
        isoCountryCode: this.isoCountryCode,
        excludedKeywords: [...this.keywordFilters],
        keyword: this.keyword,
      };
      this.$emit("submit", request);
    },
  }
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}

.action {
  text-align: right;
}
</style>

<i18n>
{
  "en": {
    "search": "Search",
    "country": "Country"
  },
  "fr": {
    "search": "Rechercher",
    "country": "Pays"
  },
  "de": {
    "search": "Forschen",
    "country": "Zahlt"
  }
}
</i18n>
