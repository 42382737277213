<template>
  <div>
    <search-form
      v-if="defaultCountryIsoCode"
      :countries="countries"
      :default-country-iso-code="defaultCountryIsoCode"
      @submit="fetchKeywords"
    ></search-form>
    <el-card
      v-if="request"
      v-loading="loadingKeywordsFromUrl"
      shadow="never"
    >
      <grid
        :data="keywordsFromUrl"
        :links="keywordsFromUrlLinks"
        :selected-url="selectedUrl"
        :account="account"
        :tags="tags"
        :can-import-keywords="isStudyIsoCountryCodeEqualsRequestIsoCountryCode"
      ></grid>
    </el-card>
    <el-card
      v-else-if="loadingKeywordsFromUrl"
      v-loading="loadingKeywordsFromUrl"
      class="loading-card"
    />
  </div>
</template>

<script>
import { keywordsSearchMixinV2 } from "@/mixins/keywordsSearchMixinV2";
import SearchForm from "@/pages/KeywordsSearch/SingleWebsite/SearchForm";
import Grid from "@/pages/KeywordsSearch/SingleWebsite/Grid";

export default {
  components: {
    SearchForm,
    Grid,
  },
  mixins: [keywordsSearchMixinV2],
  computed: {
    selectedUrl() {
      if (this.request.url) {
        return this.request.url.url;
      }
      return null;
    },
  },
  methods: {
    fetchKeywords(request) {
      this.updateRequest(request);
      this.fetchKeywordsFromUrl();
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-card {
  height: 300px;
}
</style>

<i18n>
{
  "en": {
    "loading": "Loading",
    "create_search_fail": "An error occurred",
    "no_data": "No result, try to broaden your search and check the spelling of the keyword"
  },
  "fr": {
    "loading": "Chargement en cours",
    "create_search_fail": "An error occurred",
    "no_data": "Pas de résultat, essayez d'élargir votre recherche et vérifiez l'orthographe du mot-clé"
  },
  "de": {
    "loading": "Wird geladen",
    "create_search_fail": "Ein Fehler ist aufgetreten",
    "no_data": "Kein Ergebnis, erweitern Sie Ihre Suche und überprüfen Sie die Schreibweise des Schlüsselworts"
  }
}
</i18n>
