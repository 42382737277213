<template>
	<span>
		<el-tooltip
			placement="top"
			transition="el-fade-in"
			:disabled="isDisabledTooltip">
			<div slot="content">
				<!--Here we show the assigned page the tooltip -->
				<div v-if="isPositionPageDifferentThanAssignedPage">
					<div>{{ $t("positioned_page") }}</div>
					<div>
						{{ $t("assigned_page_is") }}
						<a
							target="_blank"
							:href="assignedPath"
							@click="redirectToTrafficScreen(params.data.assignedPage)">
							{{ assignedPath }}
						</a>
						<a
							:href="params.data.assignedPage"
							target="_blank"
							><i class="fas fa-external-link-alt"></i></a>
					</div>
				</div>
			</div>

			<!-- here we show the positioned page-->
			<span v-if="params.value && params.data.position < 101" 
        class="page">

				<!-- if there's an assigned page and is different than positioned page we show a warning -->
				<i
					v-if="params.data.assignedPage && assignedPath != positionedPath"
					class="fas fa-circle" />
        <span>
		<a
            target="_blank"
			:href="positionedPath"
            @click="redirectToTrafficScreen(params.value)">
            {{ positionedPath }}
          </a>
          <a
            target="_blank"
            :href="params.value">
			<i class="fas fa-external-link-alt"></i>
          </a>

        </span>
			</span>


      <!-- Here we show the assigned pages -->
			<span
				v-else-if="params.data.assignedPage"
				class="page">
				<a
					target="_blank"
					:href="assignedPath"
					@click="redirectToTrafficScreen(params.data.assignedPage)">
					{{ assignedPath }}
				</a>

				<a
					:href="params.data.assignedPage"
					target="_blank">
					<i class="fas fa-external-link-alt"></i>
				</a>
			</span>
			<span v-else>{{ $t("no_positioned_page") }}</span>
		</el-tooltip>
	</span>
</template>

<script>
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["study", "studyLinks"]),
		isDisabledTooltip() {
			return !this.params.data.assignedPage || 
      this.params.data.assignedPage == this.params.value || 
      this.params.data.position == 101;
		},
		isPositionPageDifferentThanAssignedPage() {
			return this.params.value && this.params.data.position < 101 && 
      this.params.data.assignedPage != this.params.value && 
      this.params.data.assignedPage;
		},
		positionedPath() {
			let url = this.params.value;
			if (url) {
				if (url.charAt(url.length - 1) == "/") {
					url = url.substr(0, url.length - 1);
				}
			}
			return new URL(url).pathname;
		},
		assignedPath() {
			let url = this.params.data.assignedPage;
			if (url) {
				if (url.charAt(url.length - 1) == "/") {
					url = url.substr(0, url.length - 1);
				}
			}
			return new URL(url).pathname;
		}
	},
	methods: {
		redirectToTrafficScreen(url) {
			let filter = {
				selectedUrl: url,
				selectedType: "equal",
				mode: "SEARCH_CONSOLE_ONLY",
				period: {
					start: this.params.context.startDate,
					end: this.params.context.endDate,
					type: "CUSTOM"
				},
				frequency: "DAY"
			};
			let encodedParams = Object.keys(filter)
				.map((key) => {
					return key + "=" + JSON.stringify(filter[key]);
				})
				.join("&");

			// eslint-disable-next-line no-undef
			let host = process.env.NODE_ENV === "development" ? "http://localhost:8080" : "";
			let trafficScreenUrl = host + "/spa-app/studies/" + this.study.id + "/traffic?" + encodedParams;
			window.open(trafficScreenUrl);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.el-tooltip__popper {
	a {
		color: white;
	}
}

.page {
	cursor: pointer;
	color: $--link-color;

	&:hover {
		color: $--link-hover-color;
	}
}

.fa-circle {
	color: $--color-warning;
}
</style>

<i18n>
{
  "en": {
    "assigned_page_is": "Page manually assigned : ",
    "assigned_page": "Page manually assigned.",
    "positioned_page": "This page is positioned in Google.",
    "no_positioned_page": "No ranked page.",
    "no_assigned_page": "No assigned page."
  },
  "fr": {
    "assigned_page_is": "Page assignée manuellement : ",
    "assigned_page": "Page assignée manuellement.",
    "positioned_page": "Cette page est positionnée dans Google.",
    "no_positioned_page": "Pas de page positionnée.",
    "no_assigned_page": "Pas de page assignée."
  },
  "de": {
    "assigned_page_is": "Manuell zugewiesene Seite:",
    "assigned_page": "Manuell zugewiesene Seite.",
    "positioned_page": "Diese Seite ist in Google positioniert.",
    "no_positioned_page": "Keine Seite positioniert.",
    "no_assigned_page": "Keine Seite zugewiesen."
  }
}
</i18n>
