<template>
  <!-- <el-card shadow="never"> -->
  <div>
    <div class="actions">
      <actions
        :grid-api="gridApi"
        :column-api="columnApi"
        :tags="tags"
        :account="account"
        :can-import-keywords="canImportKeywords"
        @importKeywords="importKeywords($event)"
      >
      </actions>
    </div>
    <ag-grid-vue
      style="height: 800px"
      class="ag-theme-material"
      :column-defs="columnDefs"
      :grid-options="gridOptions"
      :row-data="rowData"
      :resizable="true"
      :filter="true"
      :floating-filter="true"
      :suppress-context-menu="true"
      :enable-range-selection="true"
      :suppress-copy-rows-to-clipboard="true"
      row-selection="multiple"
      :suppress-agg-func-in-header="true"
      :row-multi-select-with-click="true"
      @gridReady="onGridReady"
      @firstDataRendered="adjustGrid"
      @rowDataChanged="adjustGrid"
    >
    </ag-grid-vue>
  </div>
  <!-- </el-card> -->
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import { keywordsSearchImportKeywordsMixin } from "@/mixins/keywordsSearchImportKeywordsMixin";
import ScoreRenderer from "@/pages/KeywordsSearch/Shared/ScoreRenderer";
import KeywordImportedRenderer from "@/pages/KeywordsSearch/Shared/KeywordImportedRenderer";
import UrlPathRenderer from "@/pages/KeywordsSearch/Shared/UrlPathRenderer";
import Actions from "@/pages/KeywordsSearch/Shared/Actions";
import GoogleSearchRenderer from "@/components/gridRenderers/GoogleSearchRenderer";

export default {
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    ScoreRenderer,
    // eslint-disable-next-line vue/no-unused-components
    KeywordImportedRenderer,
    // eslint-disable-next-line vue/no-unused-components
    UrlPathRenderer,
    Actions,
    // eslint-disable-next-line vue/no-unused-components
    GoogleSearchRenderer,
  },
  mixins: [keywordsSearchImportKeywordsMixin],
  props: {
    data: {
      type: Array,
      default: () => null,
    },
    selectedUrl: {
      type: String,
      default: null,
    },
    account: {
      type: Object,
      default: () => null,
    },
    tags: {
      type: Array,
      default: () => null,
    },
    canImportKeywords: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: { defaultColDef: { sortable: true, resizable: true } },
      gridApi: null,
      columnApi: null,
    };
  },
  watch: {
    data() {
      this.setData();
    },
  },
  beforeMount() {
    this.setColumnDefs();
    this.gridOptions = {};
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.setData();
    },
    adjustGrid() {
      this.gridApi.sizeColumnsToFit();
    },
    setColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$i18n.t("keywords"),
          field: "keyword",
          width: 200,
          minWidth: 120,
          filter: "agTextColumnFilter",
          cellRendererFramework: "KeywordImportedRenderer",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: this.$i18n.t("url"),
          field: "url",
          width: 200,
          minWidth: 120,
          filter: "agTextColumnFilter",
          cellRendererFramework: "UrlPathRenderer",
          suppressMenu: true,
          resizable: true,
          sortable: true,
        },
        // {
        // headerName: this.$i18n.t("estimated_traffic"),
        // 	field: "estimatedTraffic",
        // 	width: 125,
        // 	type: "numericColumn",
        // 	aggFunc: "max",
        // 	sort: { direction: 'desc', priority: 0 },
        // 	valueGetter: (params) => roundNumber(params.data["estimatedTraffic"]),
        // 	filter: "agNumberColumnFilter",
        // 		filterParams: {
        // 			defaultOption: "greaterThan"
        // 		},
        // 	cellRenderer: spaceThousandRenderer,
        // 	suppressSizeToFit: true,
        // 	suppressMenu: true,
        // 	resizable: true
        // },
        {
          headerName: this.$i18n.t("volume"),
          field: "volume",
          width: 110,
          type: "numericColumn",
          aggFunc: "max",
          valueGetter: (params) => roundNumber(params.data["volume"]),
          sort: { direction: "desc", priority: 1 },
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRenderer: spaceThousandRenderer,
          suppressSizeToFit: true,
          suppressMenu: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: this.$i18n.t("position_theirs"),
          field: "position",
          width: 130,
          type: "numericColumn",
          aggFunc: "min",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          suppressSizeToFit: true,
          suppressMenu: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: this.$i18n.t("bid"),
          field: "bid",
          valueGetter: (params) => roundNumber(params.data["bid"]),
          width: 120,
          type: "numericColumn",
          aggFunc: "avg",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRenderer: spaceThousandRenderer,
          suppressSizeToFit: true,
          suppressMenu: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: this.$i18n.t("opportunity_score"),
          field: "competition",
          valueGetter: (params) => roundNumber(params.data["competition"]),
          width: 185,
          type: "numericColumn",
          aggFunc: "avg",
          filter: "agNumberColumnFilter",
          filterParams: {
            defaultOption: "greaterThan",
          },
          cellRendererFramework: "ScoreRenderer",
          suppressSizeToFit: true,
          suppressMenu: true,
          resizable: true,
          sortable: true,
        },
        {
          headerName: this.$i18n.t("actions"),
          field: "actions",
          width: 110,
          cellStyle: { textAlign: "center" },
          aggFunc: "first",
          cellRendererFramework: "GoogleSearchRenderer",
          filter: false,
          sorting: false,
          suppressSizeToFit: true,
          suppressMenu: true,
        },
      ];
    },
    setData() {
      if (!this.data) {
        this.rowData = [];
        return;
      }
      this.rowData = this.data.map((keyword) => {
        
        return {
          keyword: keyword.keyword.text,
          estimatedTraffic: keyword.volume,
          volume: keyword.volume,
          competition: 100 - keyword.competition * 100,
          position: keyword.position,
          bid: keyword.bid,
          url: keyword.url,
          actions: keyword.keyword.text,
          isImportable: this.importableKeywordsText.includes(keyword.keyword.text),
          country: keyword.country
        };
      });
    },
  },
};
var spaceThousandRenderer = function (params) {
  var roundedNumber = roundNumber(params.value);
  return roundedNumber.toLocaleString("fr");
};

let roundNumber = (number) => Math.round(number * 100) / 100;
</script>

<style scoped lang="scss">
.actions {
  text-align: right;
}

::v-deep .google-link {
  margin-left: 10px;
}

::v-deep .ag-header-cell-label {
  justify-content: center;
}
</style>

<i18n>
{
  "en": {
    "selection": "Selection",
    "keywords": "Keywords",
    "position_mine": "My pos.",
    "position_theirs": "Website ranking",
    "volume": "Volume",
    "domain_name": "Domain Name",
    "bid": "Bid",
    "opportunity_score": "Opportunity score",
    "actions": "Actions",
    "estimated_traffic": "Site estimated Traffic",
    "url": "URL",
    "add_keywords_success": "Keywords were added successfully.",
    "add_keywords_fail": "An error occurred.",
    "no_keywords_selected": "No keywords were selected ! Select keywords first in order to add them to your keywords.",
    "keywords_limit_reached": "You can only add %{count_available} keywords, you're trying to add %{count}."
  },
  "fr": {
    "selection": "Selection",
    "keywords": "Mots Clés",
    "position_mine": "Ma pos.",
    "position_theirs": "Pos. du site",
    "volume": "Volume",
    "domain_name": "Nom de domaine",
    "bid": "Enchère",
    "opportunity_score": "Score d'opportunité",
    "actions": "Actions",
    "estimated_traffic": "Traffic estimé du site",
    "url": "URL",
    "add_keywords_success": "Les mots clés ont été importés avec succès.",
    "add_keywords_fail": "Une erreur est survenue.",
    "no_keywords_selected": "Aucun mot clé sélectionné ! Sélectionnez d'abord des mots clés pour pouvoir les ajouter à vos mots clés.",
    "keywords_limit_reached": "Vous ne pouvez ajouter que %{count_available} mots clés, or vous essayez d'en ajouter %{count}."
  },
  "de": {
    "selection": "Auswahl",
    "keywords": "Schlüsselwörter",
    "position_mine": "hat eine Pos.",
    "position_theirs": "Pos. Der Seite",
    "volume": "Volumen",
    "domain_name": "Domänenname",
    "bid": "Gebot",
    "opportunity_score": "Opportunity-Score",
    "actions": "Aktionen",
    "estimated_traffic": "Geschätzter Website-Traffic",
    "url": "URL",
    "add_keywords_success": "Die Keywords wurden erfolgreich importiert.",
    "add_keywords_fail": "Ein Fehler ist aufgetreten.",
    "no_keywords_selected": "Kein Schlüsselwort ausgewählt! Wählen Sie zuerst Schlüsselwörter aus, damit Sie sie zu Ihren Schlüsselwörtern hinzufügen können.",
    "keywords_limit_reached": "Sie können nur %{count_available} Keywords hinzufügen, aber Sie versuchen, %{count} hinzuzufügen."
  }
}
</i18n>
