<template>
  <div>
    <app-introduction
      :title="$t('competition')"
      video-link="https://www.youtube.com/watch?v=apleRe0i-KY"
      :documentations="[
        {
          link: $t('documentationLink'),
          description: $t('documentation_desc'),
        },
      ]"
      :user="currentUser"
    >
      <template v-slot:right>
        <el-link :underline="false">
          <el-tooltip
            v-if="!studyLinks.competitors.hasOwnProperty('create')"
            :content="$t('competitorsLimitReached')"
            placement="left-start"
            transition="el-fade-in"
          >
            <span class="wrapper el-button">
              <el-button
                :disabled="!studyLinks.competitors.hasOwnProperty('create')"
                type="primary"
                @click="dialogVisible = true"
              >
                {{ $t("add_competitor") }}
              </el-button>
            </span>
          </el-tooltip>
          <el-button
            v-else
            type="primary"
            @click="dialogVisible = true"
          >
            {{ $t("add_competitor") }}
          </el-button>
        </el-link>
        <create-competitor-dialog
          :dialogVisible="dialogVisible"
          :study-links="studyLinks"
          :competitorUrl="competitorUrl"
          @create-competitor="createCompetitor($event)"
          @close-dialog="closeDialog()"
        >
        </create-competitor-dialog>
      </template>
    </app-introduction>
    <div class="container">
      <keyword-search-form
        @searchParametersChanged="fetchData"
        :initial-search-engine="initialSearchEngine"
        :tags="tags"
        :initial-period="period"
        :is-loading="isLoading"
      >
      </keyword-search-form>

      <el-card>
        <el-tabs
          v-model="activeTab"
          :stretch="true"
        >
          <el-tab-pane
            :label="$t('all_my_competitors')"
            name="allMyCompetitors"
          >
            <el-form>
              <competitor-type-form-item
                v-model="selectedCompetitorType"
                :competitor-type-options="competitorTypeOptions"
              >
              </competitor-type-form-item>
            </el-form>
            <charts
              :websitesStatistics="websitesStatistics"
              :shareOfVoices="shareOfVoicesOfCompetitors"
              :selectedCompetitorType="selectedCompetitorType"
              :isLoading="isLoading"
              :isLoadingShareOfVoices="isLoadingShareOfVoicesCompetitors"
            >
            </charts>
            <el-card>
              <all-competitors-grid
                v-loading="isLoading"
                :websites-statistics="websitesStatistics"
                :competitor-type-options="competitorTypeOptions"
                @refresh-data="getAllCompetitors"
              >
              </all-competitors-grid>
            </el-card>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('top_positioned_competitors')"
            name="topPositionedCompetitors"
          >
            <el-card>
              <share-of-voices-chart
                :data="shareOfVoicesAll"
                :isLoading="isLoadingShareOfVoicesAll"
              ></share-of-voices-chart>
            </el-card>
            <el-card>
              <top-competitors-grid
                v-if="allCompetitors"
                v-loading="isLoadingShareOfVoicesAll"
                :share-of-voices="shareOfVoicesAll"
                :allCompetitors="allCompetitors"
                :competitor-type-options="competitorTypeOptions"
                :study-id="studyId"
                @refresh-data="getAllCompetitors"
              >
              </top-competitors-grid>
            </el-card>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import KeywordSearchForm from "@/components/KeywordSearchForm.vue";
import CompetitorTypeFormItem from "@/components/formItems/CompetitorTypeFormItem";
import Charts from "@/pages/Competitors/Charts";
import ShareOfVoicesChart from "@/pages/Competitors/Charts/ShareOfVoicesChart";
import AllCompetitorsGrid from "@/pages/Competitors/Grids/AllCompetitorsGrid";
import CreateCompetitorDialog from "@/components/CreateCompetitorDialog";
import TopCompetitorsGrid from "@/pages/Competitors/Grids/TopCompetitorsGrid";
import { RepositoryFactory } from "@/services/repositoryFactory";
import { formatDateWithDashes } from "@/javascripts/formatDate";
import { searchEngineMixin } from "@/mixins/searchEngineMixin";

const CompetitorsRepository = RepositoryFactory.get("competitorsRepository");

export default {
  mixins: [searchEngineMixin],
  components: {
    KeywordSearchForm,
    CompetitorTypeFormItem,
    Charts,
    AllCompetitorsGrid,
    TopCompetitorsGrid,
    CreateCompetitorDialog,
    ShareOfVoicesChart,
  },
  props: {
    studyId: Number,
    currentUser: Object,
  },
  data() {
    return {
      activeTab: "allMyCompetitors",
      competitorTypeOptions: [],
      selectedCompetitorType: null,
      period: [
        DateTime.utc().startOf("week").minus({ weeks: 12 }),
        DateTime.utc().startOf("week"),
      ],
      request: null,
      trackedKeywords: null,
      websitesStatistics: null,
      shareOfVoicesOfCompetitors: null,
      shareOfVoicesAll: null,
      tags: [],
      isLoading: false,
      isLoadingShareOfVoicesCompetitors: false,
      isLoadingShareOfVoicesAll: false,
      allCompetitors: null,
      dialogVisible: false,
      competitorUrl: null,
      initialSearchEngine: {},
    };
  },
  computed: {
    ...mapState(["study", "studyLinks"]),
  },
  methods: {
    getAllCompetitors() {
      this.isLoading = true;
      CompetitorsRepository.getCompetitors(this.study.id, null)
        .then((data) => {
          this.allCompetitors = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.getCompetitors();
    },
    getCompetitors() {
      CompetitorsRepository.getCompetitors(
        this.study.id,
        this.selectedCompetitorType
      )
        .then((data) => {
          const competitors = data.data;

          // Only set competitorTypes on requests without selectedCompetitorType thus returning all possible options
          if (!this.selectedCompetitorType) {
            const competitorGroups = data.data
              .map((competitor) => competitor.attributes.competitorGroup)
              .filter((o) => o);
            const competitorTypeOptions = [...new Set(competitorGroups)]
              .filter((type) => {
                return !["direct", "indirect", "others"].includes(type);
              })
              .map((type) => {
                return {
                  value: type,
                  label: type.charAt(0).toUpperCase() + type.slice(1),
                };
              });
            competitorTypeOptions.push({
              value: "direct",
              label: this.$i18n.t("direct"),
            });
            competitorTypeOptions.push({
              value: "indirect",
              label: this.$i18n.t("indirect"),
            });
            competitorTypeOptions.push({
              value: "others",
              label: this.$i18n.t("others"),
            });
            this.competitorTypeOptions = [
              { value: null, label: this.$i18n.t("all") },
              ...competitorTypeOptions,
            ];
          }

          this.request.domains = data.data.map(
            (item) => item.attributes.domain
          );
          this.getWebsitesStatistics(competitors);
          this.getShareOfVoicesOfCompetitors();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getShareOfVoicesOfCompetitors() {
      this.isLoadingShareOfVoicesCompetitors = true;
      const request = this.getBasicRequest();
      (request["date"] = this.request.rangeDate.end),
        (request["domains"] = this.request.domains);
      this.postShareOfVoicesRequest(request)
        .then((response) => {
          this.shareOfVoicesOfCompetitors = response.data.data;
          this.isLoadingShareOfVoicesCompetitors = false;
        })
        .catch((error) => {
          this.shareOfVoicesOfCompetitors = [];
          this.isLoadingShareOfVoicesCompetitors = false;
          console.log(error);
        });
    },
    getShareOfVoices() {
      this.isLoadingShareOfVoicesAll = false;
      const request = this.getBasicRequest();
      (request["date"] = this.request.rangeDate.end), (request["limit"] = 20);
      this.postShareOfVoicesRequest(request)
        .then((response) => {
          this.shareOfVoicesAll = response.data.data;
          this.isLoadingShareOfVoicesAll = false;
        })
        .catch((error) => {
          this.shareOfVoicesAll = [];
          this.isLoadingShareOfVoicesAll = false;
          console.log(error);
        });
    },
    getBasicRequest() {
      return {
        searchEngineParameters: this.getSearchEngineParametersFromRequest(
          this.request
        ),
        keywords: this.getKeywordsForRequest(),
      };
    },
    getKeywordsForRequest() {
      return this.trackedKeywords.map((trackedKeyword) => ({
        text: trackedKeyword.text,
        locationId: trackedKeyword.locationId,
      }));
    },
    postShareOfVoicesRequest(request) {
      return this.$api.post("/positions/share-of-voices", request);
    },
    getWebsitesStatistics(competitors) {
      this.isLoading = true;

      let requestMetrics = {};
      (requestMetrics.urls = this.request.domains.map((domain) => {
        return { url: domain, type: "DOMAIN" };
      })),
        (requestMetrics.minDate = formatDateWithDashes(
          DateTime.utc().minus({ months: 1 })
        ));

      requestMetrics.isoCountryCode = this.study.isoCountryCode;
      const localRequest = {
        ...this.getBasicRequest(),
        urls: requestMetrics.urls,
        rangeDate: {
          start: this.request.rangeDate.start,
          end: this.request.rangeDate.end,
        },
        frequency: this.request.frequency === "WEEK" ? "WEEKLY" : "DAILY",
        positionsBuckets: [
          { min: 1, max: 1 },
          { min: 1, max: 3 },
          { min: 1, max: 10 },
        ],
      };

      this.$api
        .post("/positions/websites-statistics-history", localRequest)
        .then((websitesStatisticsResponse) => {
          this.$api
            .post(`/netlinking/urls/metrics`, { ...requestMetrics })
            .then((response) => {
              const data = websitesStatisticsResponse.data.data;
              const netlinkingMetrics = response.data;

              for (const competitor in competitors) {
                const indexOfCurrentCompetitor = data
                  .map((e) => e.url.url)
                  .indexOf(competitors[competitor].attributes.domain);
                if (indexOfCurrentCompetitor >= 0) {
                  data[indexOfCurrentCompetitor].id = parseInt(
                    competitors[competitor].id
                  );
                  data[indexOfCurrentCompetitor].competitorGroup =
                    competitors[competitor].attributes.competitorGroup;
                  const urlData = netlinkingMetrics.data.find((urlData) =>
                    competitors[competitor].attributes.domain.includes(
                      urlData.url.url
                    )
                  );
                  if (urlData) {
                    data[indexOfCurrentCompetitor].domainAuthority =
                      urlData.metrics.domainAuthority;
                    data[indexOfCurrentCompetitor].referringDomainsCount =
                      urlData.metrics.referringDomainsWithEquity;
                  }
                }
              }
              const indexOfHost = data
                .map((e) => e.url.url)
                .indexOf(this.study.url);
              if (data[indexOfHost]) {
                data[indexOfHost].isHost = true;
              }
              this.websitesStatistics = data;
              this.isLoading = false;
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createCompetitor(form) {
      const create = this.studyLinks.competitors.create;
      this.$api[create.method.toLowerCase()](create.href, form)
        .then(() => {
          this.getAllCompetitors();
          this.$message({
            message: this.$i18n.t("competitorCreationSuccess"),
            type: "success",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
          this.$message({
            message: this.$i18n.t("competitorCreationFailure"),
            type: "error",
            duration: 3000,
          });
        });
      this.dialogVisible = false;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    getTrackedKeywords() {
      const linkTrackedKeywordSearch = this.studyLinks.searchTrackedKeywords;
      this.tags = linkTrackedKeywordSearch.form[1].options.map((tag) => {
        return { name: tag.name, count: tag.numberOfKeywords };
      });
      this.tags.push({ name: "None", count: 0 });
      let requestTrackedKeywordsSearch = {};
      if (this.request.frequency === "DAY") {
        requestTrackedKeywordsSearch.updateFrequency = "DAILY";
      }
      if (this.request.tagsGroups && this.request.tagsGroups.length > 0) {
        let tempTagsGroups = this.request.tagsGroups.slice();
        tempTagsGroups.forEach((tagsGroup, index, object) => {
          if (tagsGroup.length === 0) {
            object.splice(index, 1);
          }
        });
        if (tempTagsGroups.length > 0) {
          requestTrackedKeywordsSearch.tagsGroups = tempTagsGroups;
        }
      }
      if (this.request.tags && this.request.tags.length > 0) {
        requestTrackedKeywordsSearch.tags = this.request.tags;
      }
      this.$api[linkTrackedKeywordSearch.method.toLowerCase()](
        linkTrackedKeywordSearch.href,
        requestTrackedKeywordsSearch
      ).then((responseTrackedKeywords) => {
        this.trackedKeywords = responseTrackedKeywords.data.data;
        this.getAllCompetitors();
        this.getShareOfVoices();
      });
    },
    fetchData(newRequest) {
      this.request = newRequest;
      this.getTrackedKeywords();
    },
    getDefaultSearchEngine() {
      const searchEngine = this.study.searchEngines.find(
        (searchEngine) => searchEngine.device.toLowerCase() === "mobile"
      );
      if (!searchEngine) {
        return this.study.searchEngines.find(
          (searchEngine) => searchEngine.id === this.study.defaultSearchEngineId
        )
      }
      return searchEngine
    },
  },
  created() {
    this.request = {
      tagsGroups: [[]],
      fromStudyId: this.study.id,
      domains: [],
      rangeDate: {
        start: this.period[0].toString().split("T")[0],
        end: this.period[1].toString().split("T")[0],
      },
      startDate: DateTime.utc().startOf("week").minus({ weeks: 12 }),
      endDate: DateTime.utc().startOf("week"),
      frequency: "WEEK",
      positionBuckets: [
        { positionMin: 1, positionMax: 1 },
        { positionMin: 1, positionMax: 3 },
        { positionMin: 1, positionMax: 10 },
      ],
      searchEngine: this.getDefaultSearchEngine(),
    };
    this.initialSearchEngine = this.getDefaultSearchEngine();
  },
  watch: {
    selectedCompetitorType() {
      this.getAllCompetitors();
    },
  },
  mounted() {
    this.getTrackedKeywords();
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  margin-bottom: 1rem;
}

.wrapper.el-button {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
}
</style>

<i18n>
{
  "en": {
    "competition": "Competition",
    "all_my_competitors": "All my competitors",
    "add_competitor": "Add competitor",
    "confirm": "Confirm",
    "url_of_competitor": "URL of the competitor",
    "competitor_group": "Group of competitors",
    "top_positioned_competitors": "Top websites",
    "documentation_desc": "How to analyse your SEO competition with SmartKeyword ?",
    "competitors_by_keywords": "Competitors by keywords",
    "indirect": "Indirect",
    "direct": "Direct",
    "others": "Others",
    "all": "All",
    "competitorCreationSuccess": "The competitor has been added succesfully",
    "competitorCreationFailure": "We encountered an error in adding the competitor",
    "competitorsLimitReached": "You have reached your maximum number of competitors, please contact us to discuss.",
    "documentationLink": "http://docs.smartkeyword.io/guide-de-smartkeyword/rapports-concurrence/les-donnees-de-concurrence-dans-smartkeyword"
  },
  "fr": {
    "competition": "Concurrence",
    "all_my_competitors": "Tous mes concurrents",
    "add_competitor": "Ajouter un concurrent",
    "confirm": "Valider",
    "url_of_competitor": "URL de votre concurrent",
    "competitor_group": "Groupe de concurrents",
    "top_positioned_competitors": "Top sites positionnés",
    "documentation_desc": "Comment analyser votre concurrence SEO avec SmartKeyword ?",
    "competitors_by_keywords": "Concurrents par mots-clés",
    "others": "Autres",
    "indirect": "Indirect",
    "direct": "Direct",
    "all": "Tous",
    "competitorCreationSuccess": "Le concurrent a été ajouté avec succès",
    "competitorCreationFailure": "Nous avons rencontré une erreur dans l'ajout du concurrent",
    "competitorsLimitReached": "Vous avez atteint votre nombre maximum de concurrents, veuillez nous contacter pour en discuter.",
    "documentationLink": "http://docs.smartkeyword.io/guide-de-smartkeyword/rapports-concurrence/les-donnees-de-concurrence-dans-smartkeyword"
  },
  "de": {
    "competition": "Übereinstimmung",
    "all_my_competitors": "Alle meine Konkurrenten",
    "add_competitor": "Fügen Sie einen Konkurrenten hinzu",
    "confirm": "Bestätigen",
    "url_of_competitor": "Die URL Ihres Konkurrenten",
    "competitor_group": "Gruppe von Konkurrenten",
    "top_positioned_competitors": "Am besten bewertete Websites",
    "documentation_desc": "Wie analysieren Sie Ihre SEO-Konkurrenz mit SmartKeyword?",
    "competitors_by_keywords": "Konkurrenten nach Schlüsselwörtern",
    "others": "Andere",
    "indirect": "Indirekt",
    "direct": "Direkte",
    "all": "Alle",
    "competitorCreationSuccess": "Der Mitbewerber wurde erfolgreich hinzugefügt",
    "competitorCreationFailure": "Beim Hinzufügen des Mitbewerbers ist ein Fehler aufgetreten",
    "competitorsLimitReached": "Sie haben Ihre maximale Teilnehmerzahl erreicht, bitte kontaktieren Sie uns, um dies zu besprechen.",
    "documentationLink": "http://docs.smartkeyword.io/guide-de-smartkeyword/rapports-concurrence/les-donnees-de-concurrence-dans-smartkeyword"
  }
}
</i18n>
