<template>
  <div>
    <el-card>
      <el-tabs
        v-model="activeTab"
        :stretch="true"
      >
        <el-tab-pane
          :label="$t('keywords')"
          name="keywords"
          lazy
        >
          <keywords
            :account="account"
            :tags="tags"
            :countries="countries"
            :default-country-iso-code="defaultCountryIsoCode"
          ></keywords>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('single_website')"
          name="singleWebsite"
        >
          <single-website
            :account="account"
            :tags="tags"
            :countries="countries"
            :default-country-iso-code="defaultCountryIsoCode"
          ></single-website>
        </el-tab-pane>
        <!-- <el-tab-pane :label="$t('websites')" name="websites" lazy>
					<websites :account="account" :tags="tags"></websites>
				</el-tab-pane> -->
        <!-- <el-tab-pane :label="$t('class_adwords')" name="classAdwords" lazy>
					<class-adwords :account="account"></class-adwords>
				</el-tab-pane> -->
        <el-tab-pane
          :label="$t('volume')"
          name="volume"
          lazy
        >
          <volume
            :account="account"
            :tags="tags"
            :countries="countries"
            :default-country-iso-code="defaultCountryIsoCode"
          ></volume>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { availableSearchEnginesMixin } from "@/mixins/availableSearchEnginesMixin";
import SingleWebsite from "@/pages/KeywordsSearch/SingleWebsite";
import Keywords from "@/pages/KeywordsSearch/Keywords";
import Volume from "@/pages/KeywordsSearch/Volume";

export default {
  components: {
    SingleWebsite,
    Keywords,
    Volume
  },
  mixins: [availableSearchEnginesMixin],
  props: {
    account: {
      type: Object,
      default: () => null
    },
  },
  data() {
    return {
      activeTab: "keywords",
      tags: [],
    };
  },
  computed: {
    defaultCountryIsoCode() {
      let defaultSearchEngine = this.availableSearchEngines.find(
        (searchEngine) => searchEngine.id === this.account.defaultSearchEngineId
      );
      if (defaultSearchEngine) {
        return defaultSearchEngine.isoCountryCode;
      } else {
        return null;
      }
    },
  },
  methods: {
    getTags() {
      this.$api
        .get(`/studies/${this.account.id}/tags`)
        .then((response) => {
          this.tags = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.getTags();
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.search-instruction {
  color: $--color-text-secondary;
  margin-bottom: 1rem;
}
.el-card {
  margin-bottom: 1rem;
}
::v-deep .el-tabs__content {
  padding-top: 15px;
}
</style>

<i18n>
{
  "en": {
    "select_search_type": "Select search type depending on what you will base you search on",
    "single_website": "By website",
    "websites": "from websites intersection",
    "keywords": "By similar keywords",
    "volume": "Fetch volumes",
    "class_adwords": "From categories"
  },
  "fr": {
    "select_search_type": "Sélectionner type de recherche en fonction de ce sur quoi vous voulez baser votre recherche",
    "single_website": "Par site",
    "websites": "Par intersection de sites",
    "keywords": "Par mots clés similaires",
    "volume": "Récupérer les volumes",
    "class_adwords": "Par catégorie"
  },
  "de": {
    "select_search_type": "Wählen Sie den Suchtyp aus, je nachdem, worauf Sie Ihre Suche stützen möchten",
    "single_website": "Nach Standort",
    "websites": "Durch Kreuzung von Standorten",
    "keywords": "Durch ähnliche Schlüsselwörter",
    "volume": "Volumes wiederherstellen",
    "class_adwords": "Nach Kategorie"
  }
}
</i18n>
