<template>
  <div>
    <search-form
      v-if="defaultCountryIsoCode"
      :countries="countries"
      :default-country-iso-code="defaultCountryIsoCode"
      @submit="fetchKeywords"
    ></search-form>

    <el-card
      v-if="request"
      v-loading="loadingKeywordsVolumes"
      shadow="never"
    >
      <grid
        :data="keywordsVolumes"
        :links="keywordsVolumesLinks"
        :account="account"
        :tags="tags"
        :iso-country-code="defaultCountryIsoCode"
        :can-import-keywords="isStudyIsoCountryCodeEqualsRequestIsoCountryCode"
      ></grid>
    </el-card>
    <el-card
      v-else-if="loadingKeywordsVolumes"
      v-loading="loadingKeywordsVolumes"
      class="loading-card"
    />
  </div>
</template>

<script>
import { keywordsSearchMixinV2 } from "@/mixins/keywordsSearchMixinV2";
import SearchForm from "@/pages/KeywordsSearch/Volume/SearchForm";
import Metrics from "@/pages/KeywordsSearch/Shared/Metrics";
import Grid from "@/pages/KeywordsSearch/Volume/Grid";

export default {
  components: {
    SearchForm,
    // eslint-disable-next-line vue/no-unused-components
    Metrics,
    Grid,
  },
  mixins: [keywordsSearchMixinV2],
  methods: {
    fetchKeywords(request) {
      this.updateRequest(request);
      this.fetchKeywordsVolummes();
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-card {
  height: 300px;
}
</style>

<i18n>
{
  "en": {
    "loading": "Loading",
    "create_search_fail": "An error occurred",
    "no_data": "Sorry, we didn't find any relevent data"
  },
  "fr": {
    "loading": "Chargement en cours",
    "create_search_fail": "An error occurred",
    "no_data": "Gloups désolé, nous n’avons pas de résultat"
  },
  "de": {
    "loading": "Wird geladen",
    "create_search_fail": "Ein Fehler ist aufgetreten",
    "no_data": "Gloups Entschuldigung, wir haben kein Ergebnis"
  }
}
</i18n>
