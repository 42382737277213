import { mapState } from "vuex";

export const keywordsSearchMixinV2 = {
  props: {
    account: Object,
    tags: Array,
    countries: Array,
    defaultCountryIsoCode: String,
  },
  data() {
    return {
      request: null,
      similarKeywordsLinks: null,
      bestKeywords: null,
      loadingBestKeywords: false,
      keywordsBroad: null,
      loadingKeywordsBroad: false,
      keywordsQuestions: null,
      loadingKeywordsQuestions: false,
      keywordsFromUrl: null,
      keywordsFromUrlLinks: null,
      loadingKeywordsFromUrl: false,
      keywordsVolumes: null,
      keywordsVolumesLinks: null,
      loadingKeywordsVolumes: false,
    };
  },
  computed: {
    isLoading() {
      return (
        this.loadingBestKeywords ||
        this.loadingKeywordsBroad ||
        this.loadingKeywordsQuestions
      );
    },
    ...mapState(["study"]),
    isStudyIsoCountryCodeEqualsRequestIsoCountryCode() {
      if (!this.request) return false;
      return this.study.isoCountryCode == this.request.isoCountryCode;
    },
  },
  methods: {
    updateRequest(request) {
      this.request = request;
    },
    fetchBestKeywords() {
      const requestCloseMatch = { ...this.request, reportType: "CLOSE_MATCH" };
      this.loadingBestKeywords = true;
      this.$api
        .post(
          `/studies/${this.study.id}/keywords/similar-keywords`,
          requestCloseMatch
        )
        .then((response) => {
          this.bestKeywords = response.data.data.map(element => 
            ({ country: this.request.isoCountryCode ,...element}));
          this.similarKeywordsLinks = response.data.links;
          this.loadingBestKeywords = false;
        })
        .catch((error) => {
          this.loadingBestKeywords = false;
          console.error(error);
          this.bestKeywords = [];
          this.$message.error(this.$i18n.t("no_data"));
        });
    },
    fetchKeywordsBroad() {
      const requestBroadMatch = { ...this.request, reportType: "BROAD_MATCH" };
      this.loadingKeywordsBroad = true;
      this.$api
        .post(
          `/studies/${this.study.id}/keywords/similar-keywords`,
          requestBroadMatch
        )
        .then((response) => {
          this.keywordsBroad = response.data.data.map(element => 
            ({ country: this.request.isoCountryCode ,...element}));

          this.similarKeywordsLinks = response.data.links;
          this.loadingKeywordsBroad = false;
        })
        .catch((error) => {
          this.loadingKeywordsBroad = false;
          console.error(error);
          this.keywordsBroad = [];
          this.$message.error(this.$i18n.t("no_data"));
        });
    },
    fetchKeywordsQuestions() {
      const requestQuestions = { ...this.request, reportType: "QUESTIONS" };
      this.loadingkeywordsQuestions = true;
      this.$api
        .post(
          `/studies/${this.study.id}/keywords/similar-keywords`,
          requestQuestions
        )
        .then((response) => {
          this.keywordsQuestions = response.data.data.map(element => 
            ({ country: this.request.isoCountryCode ,...element}));
          this.similarKeywordsLinks = response.data.links;
          this.loadingkeywordsQuestions = false;
        })
        .catch((error) => {
          this.loadingkeywordsQuestions = false;
          console.error(error);
          this.keywordsQuestions = [];
          this.$message.error(this.$i18n.t("no_data"));
        });
    },
    fetchKeywordsFromUrl() {
      const requestKeywordsFromUrl = { ...this.request };
      this.loadingKeywordsFromUrl = true;
      this.$api
        .post(
          `/studies/${this.study.id}/keywords/from-url`,
          requestKeywordsFromUrl
        )
        .then((response) => {
          this.keywordsFromUrl = response.data.data.map(element => 
            ({ country: this.request.isoCountryCode, ...element}));
          this.keywordsFromUrlLinks = response.data.links;
          this.loadingKeywordsFromUrl = false;
        })
        .catch((error) => {
          this.loadingKeywordsFromUrl = false;
          console.error(error);
          this.keywordsFromUrl = [];
          this.keywordsFromUrlLinks = null;
          this.$message.error(this.$i18n.t("no_data"));
        });
    },
    fetchKeywordsVolummes() {
      const requestKeywordsVolumes = {
        ...this.request,
        keywords: this.request.keywords.map((keyword) => {
          return { text: keyword };
        }),
      };
      this.loadingKeywordsVolumes = true;
      this.$api
        .post(
          `/studies/${this.study.id}/keywords/volumes/search`,
          requestKeywordsVolumes
        )
        .then((response) => {
          this.keywordsVolumes = response.data.data.map(element => ({
            country: this.request.isoCountryCode, ...element }));
          this.keywordsVolumesLinks = response.data.links;
          this.loadingKeywordsVolumes = false;
        })
        .catch((error) => {
          this.loadingKeywordsVolumes = false;
          console.error(error);
          this.keywordsVolumes = [];
          this.keywordsVolumesLinks = null;
          this.$message.error(this.$i18n.t("no_data"));
        });
    },
  },
};
