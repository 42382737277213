<template>
  <div>
    <search-form
      v-if="defaultCountryIsoCode"
      :countries="countries"
      :default-country-iso-code="defaultCountryIsoCode"
      @submit="updateRequest"
    ></search-form>
    <el-card
      v-if="request"
      shadow="never"
    >
      <el-tabs
        v-model="activeTab"
        :stretch="true"
      >
        <el-tab-pane
          :label="$t('strict')"
          name="strict"
          lazy
        >
          <grid
            v-loading="loadingBestKeywords"
            :data="bestKeywords"
            :links="similarKeywordsLinks"
            :request="request"
            :active-tab="activeTab"
            tab-name="strict"
            :account="account"
            :tags="tags"
            :can-import-keywords="isStudyIsoCountryCodeEqualsRequestIsoCountryCode"
            @fetch="fetchBestKeywords()"
          ></grid>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('broad')"
          name="broad"
          lazy
        >
          <grid
            v-loading="loadingKeywordsBroad"
            :data="keywordsBroad"
            :links="similarKeywordsLinks"
            :request="request"
            :active-tab="activeTab"
            tab-name="broad"
            :account="account"
            :tags="tags"
            :can-import-keywords="isStudyIsoCountryCodeEqualsRequestIsoCountryCode"
            @fetch="fetchKeywordsBroad()"
          ></grid>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('questions')"
          name="questions"
          lazy
>
          <grid
            v-loading="loadingKeywordsQuestions"
            :data="keywordsQuestions"
            :links="similarKeywordsLinks"
            :request="request"
            :active-tab="activeTab"
            tab-name="questions"
            :account="account"
            :tags="tags"
            :can-import-keywords="isStudyIsoCountryCodeEqualsRequestIsoCountryCode"
            @fetch="fetchKeywordsQuestions()"
          ></grid>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-card
      v-else-if="isLoading"
      v-loading="isLoading"
      class="loading-card"
    />
  </div>
</template>

<script>
import { keywordsSearchMixinV2 } from "@/mixins/keywordsSearchMixinV2";
import SearchForm from "@/pages/KeywordsSearch/Keywords/SearchForm";
import Metrics from "@/pages/KeywordsSearch/Shared/Metrics";
import Grid from "@/pages/KeywordsSearch/Shared/Grid";

export default {
  components: {
    SearchForm,
    // eslint-disable-next-line vue/no-unused-components
    Metrics,
    Grid,
  },
  mixins: [keywordsSearchMixinV2],
  data() {
    return {
      activeTab: "strict",
    };
  },
};
</script>

<style scoped lang="scss">
.el-card {
  margin-bottom: 1rem;
}
.loading-card {
  height: 300px;
}
</style>

<i18n>
{
  "en": {
    "loading": "Loading",
    "create_search_fail": "An error occurred",
    "no_data": "No result, try to broaden your search and check the spelling of the keyword",
    "strict": "Strict search",
    "broad": "Broad search",
    "questions": "Questions"
  },
  "fr": {
    "loading": "Chargement en cours",
    "create_search_fail": "An error occurred",
    "no_data": "Pas de résultat, essayez d'élargir votre recherche et vérifiez l'orthographe du mot-clé",
    "strict": "Recherche stricte",
    "broad": "Recherche large",
    "questions": "Questions"
  },
  "de": {
    "loading": "Wird geladen",
    "create_search_fail": "Ein Fehler ist aufgetreten",
    "no_data": "Kein Ergebnis, erweitern Sie Ihre Suche und überprüfen Sie die Schreibweise des Schlüsselworts",
    "strict": "Strenge Suche",
    "broad": "Breite Suche",
    "questions": "Fragen"
  }
}
</i18n>
