<template>
  <div>
    <app-introduction
      :title="$t('competitor', { domain: domain })"
      :user="currentUser"
    ></app-introduction>

    <div class="container">
      <keyword-search-form
        @searchParametersChanged="updateRequestParams"
        :initial-search-engine="initialSearchEngine"
        :tags="tags"
        :initial-period="period"
        :is-loading="isSearchOngoing"
      >
      </keyword-search-form>
      <div v-if="studyResponse">
        <charts
          v-if="trackedKeywords && websites"
          :params="params"
          :study-response="studyResponse"
          :tracked-keywords="trackedKeywords"
          :websites="websites"
          :user="currentUser"
        ></charts>
        <el-card>
          <el-tabs
            v-model="activeTab"
            :stretch="true"
          >
            <el-tab-pane name="allKeywords">
              <div slot="label">
                {{ $t("allKeywords") }}
                <span v-if="allKeywordsCount">({{ allKeywordsCount }})</span>
                <span v-else>(<i class="fas fa-spinner fa-spin"></i>)</span>
              </div>
              <all-keywords
                v-if="websites"
                :study-id="study.id"
                :params="params"
                :websites="websites"
                :current-user="currentUser"
                @show-keyword="showKeyword"
                @show-task="showTask"
              >
              </all-keywords>
            </el-tab-pane>
            <el-tab-pane name="topVariations">
              <div slot="label">
                {{ $t("topVariations") }}
                <span v-if="isSearchOngoing"
                  >(<i class="fas fa-spinner fa-spin"></i>)</span
                >
                <span
                  v-else-if="
                    topMovements.highest.metrics &&
                    topMovements.highest.metrics.numberOfKeywords != null &&
                    topMovements.lowest.metrics &&
                    topMovements.lowest.metrics.numberOfKeywords != null
                  "
                >
                  ({{ topMovements.highest.metrics.numberOfKeywords
                  }}<i class="fas fa-arrow-up"></i>
                  {{ topMovements.lowest.metrics.numberOfKeywords
                  }}<i class="fas fa-arrow-down"></i>)
                </span>
              </div>
              <el-row>
                <el-col
                  :xs="24"
                  :lg="12"
                >
                  <top-movements
                    :study-id="study.id"
                    :keywords-data="topMovements.highest.keywordsData"
                    :total-traffic-variation="
                      topMovements.highest.metrics.totalTrafficVariation
                    "
                    top-type="ENTRY"
                    :start-date="startDate"
                    :end-date="endDate"
                    :exportableData="true"
                    @show-keyword="showKeyword"
                  >
                  </top-movements>
                </el-col>
                <el-col
                  :xs="24"
                  :lg="12"
                >
                  <top-movements
                    :study-id="study.id"
                    :keywords-data="topMovements.lowest.keywordsData"
                    :total-traffic-variation="
                      topMovements.lowest.metrics.totalTrafficVariation
                    "
                    top-type="EXIT"
                    :start-date="startDate"
                    :end-date="endDate"
                    :exportableData="true"
                    @show-keyword="showKeyword"
                  >
                  </top-movements>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane name="topOneThree">
              <div slot="label">
                {{ $t("top") }}
                <div>
                  {{ $t("top_1_3") }}
                  <span
                    v-if="
                      topMovements1To3.entries.count !== null &&
                      topMovements1To3.exits.count !== null
                    "
                  >
                    ({{ topMovements1To3.entries.count
                    }}<i class="fas fa-arrow-up"></i>
                    {{ topMovements1To3.exits.count
                    }}<i class="fas fa-arrow-down"></i>)
                  </span>
                  <span v-else>(<i class="fas fa-spinner fa-spin"></i>)</span>
                </div>
              </div>
              <el-row>
                <el-col
                  :xs="24"
                  :lg="12"
                >
                  <top-movements
                    :study-id="study.id"
                    :keywords-data="topMovements1To3.entries.keywordsData"
                    :total-traffic-variation="
                      topMovements1To3.totalTrafficVariationEntry
                    "
                    :start="1"
                    :end="3"
                    top-type="ENTRY"
                    :start-date="startDate"
                    :end-date="endDate"
                    :exportableData="true"
                    @show-keyword="showKeyword"
                  >
                  </top-movements>
                </el-col>
                <el-col
                  :xs="24"
                  :lg="12"
                >
                  <top-movements
                    :study-id="study.id"
                    :keywords-data="topMovements1To3.exits.keywordsData"
                    :total-traffic-variation="
                      topMovements1To3.totalTrafficVariationExit
                    "
                    :start="1"
                    :end="3"
                    top-type="EXIT"
                    :start-date="startDate"
                    :end-date="endDate"
                    :exportableData="true"
                    @show-keyword="showKeyword"
                  >
                  </top-movements>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane name="topFourTen">
              <div slot="label">
                {{ $t("top") }}
                <div>
                  {{ $t("top_4_10") }}
                  <span
                    v-if="
                      topMovements4To10.entries.count !== null &&
                      topMovements4To10.exits.count !== null
                    "
                  >
                    ({{ topMovements4To10.entries.count
                    }}<i class="fas fa-arrow-up"></i>
                    {{ topMovements4To10.exits.count
                    }}<i class="fas fa-arrow-down"></i>)
                  </span>
                  <span v-else>(<i class="fas fa-spinner fa-spin"></i>)</span>
                </div>
              </div>
              <el-row>
                <el-col
                  :xs="24"
                  :lg="12"
                >
                  <top-movements
                    :study-id="study.id"
                    :keywords-data="topMovements4To10.entries.keywordsData"
                    :total-traffic-variation="
                      topMovements4To10.totalTrafficVariationEntry
                    "
                    :start="4"
                    :end="10"
                    top-type="ENTRY"
                    :start-date="startDate"
                    :end-date="endDate"
                    :exportableData="true"
                    @show-keyword="showKeyword"
                  >
                  </top-movements>
                </el-col>
                <el-col
                  :xs="24"
                  :lg="12"
                >
                  <top-movements
                    :study-id="study.id"
                    :keywords-data="topMovements4To10.exits.keywordsData"
                    :total-traffic-variation="
                      topMovements4To10.totalTrafficVariationExit
                    "
                    :start="4"
                    :end="10"
                    top-type="EXIT"
                    :start-date="startDate"
                    :end-date="endDate"
                    :exportableData="true"
                    @show-keyword="showKeyword"
                  >
                  </top-movements>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane name="topElevenThirty">
              <div slot="label">
                {{ $t("top") }}
                <div>
                  {{ $t("top_11_30") }}
                  <span
                    v-if="
                      topMovements11To30.entries.count !== null &&
                      topMovements11To30.exits.count !== null
                    "
                  >
                    ({{ topMovements11To30.entries.count
                    }}<i class="fas fa-arrow-up"></i>
                    {{ topMovements11To30.exits.count
                    }}<i class="fas fa-arrow-down"></i>)
                  </span>
                  <span v-else>(<i class="fas fa-spinner fa-spin"></i>)</span>
                </div>
              </div>
              <el-row>
                <el-col
                  :xs="24"
                  :lg="12"
                >
                  <top-movements
                    :study-id="study.id"
                    :keywords-data="topMovements11To30.entries.keywordsData"
                    :total-traffic-variation="
                      topMovements11To30.totalTrafficVariationEntry
                    "
                    :start="11"
                    :end="30"
                    top-type="ENTRY"
                    :start-date="startDate"
                    :end-date="endDate"
                    :exportableData="true"
                    @show-keyword="showKeyword"
                  >
                  </top-movements>
                </el-col>
                <el-col
                  :xs="24"
                  :lg="12"
                >
                  <top-movements
                    :study-id="study.id"
                    :keywords-data="topMovements11To30.exits.keywordsData"
                    :total-traffic-variation="
                      topMovements11To30.totalTrafficVariationExit
                    "
                    :start="11"
                    :end="30"
                    top-type="EXIT"
                    :start-date="startDate"
                    :end-date="endDate"
                    :exportableData="true"
                    @show-keyword="showKeyword"
                  >
                  </top-movements>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane
              name="netlinking"
              :label="$t('netlinking')"
            >
              <netlinking-tab
                :studyId="studyId"
                :competitorId="competitorId"
                :competitorUrl="competitorUrl"
              >
              </netlinking-tab>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
    </div>
    <keyword-dialog
      v-if="keywordDialogVisible"
      v-model="keywordDialogVisible"
      :keyword-id="selectedKeywordId"
      :initial-search-engine="this.params.searchEngine"
      :initial-date="period[1]"
      :initial-past-date="period[0]"
      :initial-frequency="params.frequency"
      :current-user="currentUser"
      :user-options="userOptions"
      :studyId="studyId"
    ></keyword-dialog>
    <el-dialog
      :visible.sync="taskDialogVisible"
      :close-on-click-modal="false"
      width="62%"
    >
      <task-creation-form
        v-if="selectedKeywordForTask"
        :key="selectedKeywordForTask.id"
        :study-id="study.id"
        :title="
          $t('workOnKeyword', { keywordText: selectedKeywordForTask.text })
        "
        :keywords="[
          { text: selectedKeywordForTask.text, id: selectedKeywordForTask.id },
        ]"
        :current-user="currentUser"
        :assigned-to-user-id="currentUser.id"
        :user-options="userOptions"
        @on-created-task="taskDialogVisible = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";
import AppIntroduction from "@/components/AppIntroduction";
import KeywordSearchForm from "@/components/KeywordSearchForm.vue";
import Charts from "@/pages/Competitor/Charts";
import AllKeywords from "@/pages/Competitor/AllKeywords";
import TopMovements from "@/pages/Keywords/TopMovements";
import TaskCreationForm from "@/components/task/TaskCreationForm";
import KeywordDialog from "@/pages/KeywordDialog";
import NetlinkingTab from "@/pages/Competitor/Netlinking/NetlinkingExportTab";
import { RepositoryFactory } from "@/services/repositoryFactory";
import { formatDateWithDashes } from "@/javascripts/formatDate";
import { topMovementsDataMixin } from "@/mixins/topMovementsDataMixin";
import { searchEngineMixin } from "@/mixins/searchEngineMixin";

const CompetitorsRepository = RepositoryFactory.get("competitorsRepository");
const CompaniesRepository = RepositoryFactory.get("companies");

export default {
  mixins: [topMovementsDataMixin, searchEngineMixin],
  props: {
    companyId: Number,
    competitorId: Number,
    currentUser: Object,
    domain: String,
    studyId: Number,
  },
  components: {
    AllKeywords,
    AppIntroduction,
    Charts,
    KeywordDialog,
    NetlinkingTab,
    KeywordSearchForm,
    TaskCreationForm,
    TopMovements,
  },
  data() {
    return {
      studyResponse: null,
      trackedKeywords: null,
      websites: null,
      activeTab: "allKeywords",
      selectedKeywordId: null,
      keywordDialogVisible: false,
      userOptions: [],
      tags: [],
      params: {
        tagsGroups: [[]],
        tags: [],
        startDate: DateTime.utc().startOf("week").minus({ weeks: 12 }),
        endDate: DateTime.utc().startOf("week"),
        frequency: "WEEK",
      },
      allKeywordsCount: null,
      topMovements: {
        highest: {
          count: null,
          keywordsData: null,
          metrics: {
            totalTrafficVariation: null,
            numberOfKeywords: null,
          },
        },
        lowest: {
          count: null,
          keywordsData: null,
          metrics: {
            totalTrafficVariation: null,
            numberOfKeywords: null,
          },
        },
      },
      topMovements1To3: {
        entries: {
          count: null,
          keywordsData: null,
        },
        exits: {
          count: null,
          keywordsData: null,
        },
      },
      topMovements4To10: {
        entries: {
          count: null,
          keywordsData: null,
        },
        exits: {
          count: null,
          keywordsData: null,
        },
      },
      topMovements11To30: {
        entries: {
          count: null,
          keywordsData: null,
        },
        exits: {
          count: null,
          keywordsData: null,
        },
      },
      url: null,
      form: [],
      isLoading: false,
      isLoadingTopMovements: false,
      isLoadingTrackedKeywords: false,
      taskDialogVisible: false,
      selectedKeywordForTask: null,
      initialSearchEngine: {},
    };
  },
  computed: {
    period() {
      return [this.startDate, this.endDate];
    },
    startDate() {
      return this.params.startDate;
    },
    endDate() {
      return this.params.endDate;
    },
    competitorUrl() {
      if (this.websites && this.websites.competitor) {
        return this.websites.competitor.domain;
      } else {
        return "";
      }
    },
    ...mapState(["study"]),
    isSearchOngoing() {
      return (
        this.isLoading ||
        this.isLoadingTopMovements ||
        this.isLoadingTrackedKeywords
      );
    },
  },
  methods: {
    fetchStudy() {
      this.$api
        .get(`/studies/${this.studyId}`)
        .then((response) => {
          this.studyResponse = response.data;
          const linkTrackedKeywordSearch =
            response.data.links.searchTrackedKeywords;
          this.form = linkTrackedKeywordSearch.form;
          this.getTrackedKeywords();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTrackedKeywords() {
      this.isLoadingTrackedKeywords = true;

      this.url = this.studyResponse.data.url;
      const linkTrackedKeywordSearch =
        this.studyResponse.links.searchTrackedKeywords;
      this.tags = linkTrackedKeywordSearch.form[1].options.map((tag) => {
        return { name: tag.name, count: tag.numberOfKeywords };
      });
      this.tags.push({ name: "None", count: 0 });
      let requestTrackedKeywordsSearch = {};
      if (this.params.frequency === "DAY") {
        requestTrackedKeywordsSearch.updateFrequency = "DAILY";
      }

      if (this.params.tagsGroups && this.params.tagsGroups.length > 0) {
        let tempTagsGroups = this.params.tagsGroups.slice();
        tempTagsGroups.forEach((tagsGroup, index, object) => {
          if (tagsGroup.length == 0) {
            object.splice(index, 1);
          }
        });
        if (tempTagsGroups.length > 0) {
          requestTrackedKeywordsSearch.tagsGroups = tempTagsGroups;
        }
      }

      if (this.params.tags && this.params.tags.length > 0) {
        requestTrackedKeywordsSearch.tags = this.params.tags;
      }

      this.$api[linkTrackedKeywordSearch.method.toLowerCase()](
        linkTrackedKeywordSearch.href,
        requestTrackedKeywordsSearch
      ).then((responseTrackedKeywords) => {
        this.trackedKeywords = responseTrackedKeywords.data.data;
        this.allKeywordsCount = responseTrackedKeywords.data.meta.count;
        this.isLoadingTrackedKeywords = false;
        this.getCompetitor();
      });
    },
    fetchUsers() {
      CompaniesRepository.getCompanyUsersForTasks(this.companyId).then(
        (data) => {
          this.userOptions = data.data.map((user) => ({
            id: parseInt(user.id),
            firstName: user.attributes.firstName,
            lastName: user.attributes.lastName,
          }));
        }
      );
    },
    getCompetitor() {
      this.isLoading = true;
      CompetitorsRepository.getCompetitor(this.study.id, this.competitorId)
        .then((data) => {
          const websites = {};
          for (const item of data.data) {
            const website = { id: parseInt(item.id), ...item.attributes };
            if (website.domain === this.study.url) {
              websites.self = website;
            } else {
              websites.competitor = website;
            }
          }
          this.websites = websites;
          this.isLoading = false;
          this.getTopMovementsData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTopMovementsData() {
      this.isLoadingTopMovements = true;
      let requestApi = {
        url: {
          url: this.websites.competitor.domain,
          type: "DOMAIN",
        },
        searchEngineParameters: this.getSearchEngineParametersFromRequest(
          this.params
        ),
        positionsBuckets: [
          { min: 1, max: 3 },
          { min: 4, max: 10 },
          { min: 11, max: 30 },
        ],
        frequency: this.params.frequency === "WEEK" ? "WEEKLY" : "DAILY",
        rangeDate: {
          start: formatDateWithDashes(this.params.startDate),
          end: formatDateWithDashes(this.params.endDate),
        },
      };
      requestApi.keywords = this.trackedKeywords.map((trackedKeyword) => {
        return {
          text: trackedKeyword.text,
          locationId: trackedKeyword.locationId,
        };
      });
      this.$api
        .post(`/positions/top-movements`, requestApi)
        .then((response) => {
          const topMovementsList = this.getTopMovementsDataFromResponse(
            response,
            this.trackedKeywords,
            this.topMovements,
            this.topMovements1To3,
            this.topMovements4To10,
            this.topMovements11To30
          );
          this.topMovements = topMovementsList.topMovements;
          this.topMovements1To3 = topMovementsList.topMovements1To3;
          this.topMovements4To10 = topMovementsList.topMovements4To10;
          this.topMovements11To30 = topMovementsList.topMovements11To30;
          this.isLoadingTopMovements = false;
        })
        .catch((error) => {
          this.isLoadingTopMovements = false;
          console.log(error);
        });
    },
    showKeyword(keywordId) {
      this.selectedKeywordId = keywordId;
      this.keywordDialogVisible = true;
    },
    showTask(keyword) {
      this.selectedKeywordForTask = keyword;
      this.taskDialogVisible = true;
    },
    updateRequestParams(newParams) {
      this.params = newParams;
    },
    getDefaultSearchEngine() {
      const searchEngine = this.study.searchEngines.find(
        (searchEngine) => searchEngine.device.toLowerCase() === "mobile"
      );
      if (!searchEngine) {
        return this.study.searchEngines.find(
          (searchEngine) => searchEngine.id === this.study.defaultSearchEngineId
        )
      }
      return searchEngine
    },
  },
  created() {
    this.fetchStudy();
    this.fetchUsers();
    this.initialSearchEngine = this.getDefaultSearchEngine();
    this.params.searchEngine = this.getDefaultSearchEngine();
  },
  watch: {
    params: function (val, oldVal) {
      if (this.websites) {
        if (
          val.frequency != oldVal.frequency ||
          JSON.stringify(val.tags) != JSON.stringify(oldVal.tags) ||
          JSON.stringify(val.tagsGroups) != JSON.stringify(oldVal.tagsGroups)
        ) {
          this.getTrackedKeywords();
        } else {
          this.getTopMovementsData();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.el-card {
  margin-bottom: 1rem;
}

::v-deep .is-top {
  line-height: 15px;
}

.fa-arrow-up {
  color: $--color-success;
}

.fa-arrow-down {
  color: $--color-danger;
}
</style>

<i18n>
{
  "en": {
    "allKeywords": "All keywords",
    "competitor": "Competitor: %{domain}",
    "top": "Entrances / Exits",
    "top_1_3": "Top 3 Ranking",
    "top_4_10": "4 to 10 Ranking",
    "top_11_30": "11 to 30 Ranking",
    "topVariations": "Top variations",
    "netlinking": "Netlinking",
    "workOnKeyword": "Work on keyword: %{keywordText}"
  },
  "fr": {
    "allKeywords": "Tous les mots clés",
    "competitor": "Concurrent : %{domain}",
    "top": "Entrées / Sorties",
    "top_1_3": "Positions 1 à 3",
    "top_4_10": "Positions 4 à 10",
    "top_11_30": "Positions 11 à 30",
    "netlinking": "Netlinking",
    "topVariations": "Top variations",
    "workOnKeyword": "Travailler le mot clé : %{keywordText}"
  },
  "de": {
    "allKeywords": "Alle Schlüsselwörter",
    "competitor": "Gleichzeitig: %{domain}",
    "top": "Einträge Ausgänge",
    "top_1_3": "Platz 1 bis 3",
    "top_4_10": "Platz 4 bis 10",
    "top_11_30": "Plätze 11 bis 30",
    "netlinking": "Netzverlinkung",
    "topVariations": "Top-Varianten",
    "workOnKeyword": "Bearbeiten Sie das Schlüsselwort: %{keywordText}"
  }
}
</i18n>
